<template>
	<b-modal id="cancel-dispatch" :title="title" ok-title="Proceed" ref="modal" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Are you sure you want to cancel
			<b class="numFont">{{ selDispatch.dispatchId }}</b>
			?
		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { LocationUtil } from '@/utils/locationUtil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// API
import dispatchApi from '@/api/dispatchApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'cancel-dispatch',
	components: {
		Loading,
	},
	data() {
		return {
			selDispatch: {},
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Cancel Dispatch ' + this.selDispatch.dispatchId;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateDispatch', (selDispatch) => {
			if (!_.isEmpty(selDispatch)) {
				this.selDispatch = DispatchUtil.cleanupFields(selDispatch);
			}
		});
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			// update fields
			this.selDispatch.dateCancelled = DateUtil.getCurrentTimestamp();
			this.selDispatch.cancelledBy = this.loggedUser.id;

			// update geoaddresses
			this.selDispatch.source.geoaddress = LocationUtil.getGeoaddress(
				this.selDispatch.source.geoaddress
			);
			this.selDispatch.destination.geoaddress = LocationUtil.getGeoaddress(
				this.selDispatch.destination.geoaddress
			);

			let dispatchId = this.selDispatch.dispatchId;
			try {
				let { data } = await dispatchApi.cancelDispatch(
					this.selDispatch,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					this.$toaster.success(`Dispatch "${dispatchId}" was cancelled successfully.`);
					this.$refs.modal.hide();
					EventBus.$emit('onCloseSaveDispatch', data.dispatch);
				} else {
					this.$toaster.error(data.message);
				}
			} catch (_error) {
				this.$toaster.error(`Error cancelling dispatch "${dispatchId}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateDispatch');
	},
};
</script>