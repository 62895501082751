<template>
    <b-row class="px-2 mt-4">
        <b-col sm="6">
            <b-row>
                <b-col sm="4" class="form-sign-field"> Dispatched by: </b-col>
                <b-col sm="7">
                    <b-row>
                        <b-col class="form-sign-value">
                            &nbsp;{{ deployedByUser }}&nbsp;
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="form-sign-label">
                            (Signature over Printed Name)
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>

        <b-col sm="6">
            <b-row class="mt-0">
                <b-col sm="4" class="form-sign-field"> Received by: </b-col>
                <b-col sm="7">
                    <b-row>
                        <b-col class="form-sign-value">
                            &nbsp;{{ receivedByUser }}&nbsp;
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="form-sign-label">
                            (Signature over Printed Name)
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'dispatch-summary-assets',
    props: {
        selDispatch: {
            type: Object,
            required: true,
        },
        deployedByUser: {
            type: String,
            required: true,
        },
        receivedByUser: {
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped>
.form-sign-field {
    font-size: 14px;
    line-height: 15px;
    color: #484554;
}

.form-sign-value {
    font-size: 15px;
    text-align: center;
    line-height: 15px;
    color: #484554;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #4A4A4A;
}

.form-sign-label {
    font-size: 12px;
    text-align: center;
    color: #484554;
}
</style>