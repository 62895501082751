<template>
    <b-row class="mt-4">
        <b-col sm="3" align="left">
        </b-col>
        <b-col align="right">
            <span class="copy-owner">{{ copyOwner }} Copy</span>
            &nbsp;|
            <span class="pagination-status" v-show="totalPage > 1">
                Page <strong>{{ currPage + 1 }}</strong> of <strong>{{ totalPage }}</strong>
            </span>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'dispatch-summary-footer',
    props: {
        currPage: {
            type: Number,
            required: true,
        },
        totalPage: {
            type: Number,
            required: true,
        },
        copyOwner: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.footer-col {
    padding-top: 0.7em;
    font-size: 12px;
}

.pagination-status {
    margin-left: 5px;
    text-transform: uppercase;
    font-size: 12px !important;
}

.copy-owner {
    text-transform: uppercase;
}
</style>